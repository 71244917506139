import React, { FC } from 'react';
import { LoadingSpinner } from '../icons/OurIcons';

export interface DMPropertyIncrementorProps {
  index: number;
  totalPropertyRecordsFiltered: number;
  loadingProperties: boolean;
  onIncrementorClick: (incrementValue: number) => void; // New prop to accept the increment function from the parent
}

const DMPropertyIncrementor: FC<DMPropertyIncrementorProps> = ({
  index,
  totalPropertyRecordsFiltered,
  loadingProperties,
  onIncrementorClick
}) => {

  const isNextDisabled = () => {
    return index === totalPropertyRecordsFiltered - 1; // Disable if it's the last property
  };

  const isPrevDisabled = () => {
    return index === 0; // Disable if it's the first property
  };

  return (
    <div className={'flex justify-between absolute p-0 left-6'}>
      <button
        className={`p-button p-component p-button-text
              ${isPrevDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
        onClick={() => onIncrementorClick(-1)}
        disabled={isPrevDisabled()}
      >
        {'<'}
      </button>
      {loadingProperties ? <LoadingSpinner /> : (
        <span className="text-sm px-2">
              Property { index + 1} of {totalPropertyRecordsFiltered}
        </span>
      )}
      <button
        className={`p-button p-component p-button-text
              ${isNextDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
        onClick={() => onIncrementorClick(1)}
        disabled={isNextDisabled()}
      >
        {'>'}
      </button>
    </div>
  );
};

export default DMPropertyIncrementor;
