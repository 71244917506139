import React, { FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { deleteListSortPropertyJoin } from '../../api/ListSortPropertyJoin/deleteListSortPropertyJoin';

type ConfirmationModalProps = {
  propertyId: string;
  onHide: () => void;
  activeListSortId: string;
  handleDeleteConfirm: () => void;
};

const ConfirmDeleteLSPJModal: FC<ConfirmationModalProps> = ({ propertyId, onHide, activeListSortId, handleDeleteConfirm }) => {
  const handleConfirmDelete = () => {
    deleteListSortPropertyJoin({ listSortId: activeListSortId, propertyId });
    console.log(`deleteListSortPropertyJoin({ listSortId: ${activeListSortId}, propertyId: ${propertyId} });`);
    onHide();
    handleDeleteConfirm();
  };

  return (
    <Dialog visible={true} style={{ width: '50vw', height: 'auto', padding: '12px', backgroundColor: 'white' }} onHide={onHide}>
      <div className='flex flex-col items-center justify-center'>
        <h1 className='text-2xl font-bold text-gray-800'>Confirm Delete</h1>
        <div className="confirmation-content">
          <div className='text-lg text-gray-800 m-4'>
            Are you sure you want to delete this property's decision?
          </div>
          <div className="flex justify-between m-6">
            <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" />
            <Button label="Confirm" icon="pi pi-check" onClick={handleConfirmDelete} autoFocus className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out" />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteLSPJModal;
