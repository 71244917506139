import React, { type FC } from 'react';
import { ListSortProps } from '../../utility/types';
import ViewHeader from '../common/ViewHeader';
import SelectListSortDropdown from './SelectListSortDropdown';

interface DecisionMakingHeaderProps {
  title: string;
  activeListSort: ListSortProps;
  listSorts: ListSortProps[];
  navigateToSelectedListSort: (listSortId: string) => void;
}

const DecisionMakingHeader: FC<DecisionMakingHeaderProps> = ({ title, activeListSort, listSorts, navigateToSelectedListSort }) => {
  return (
    <div id='DecisionMakingHeader-container' className='flex whitespace-nowrap flex-col px-3 border-5 border-indigo-200'>
      <ViewHeader title={title} />

      <div id='DecisionMakingHeader-list-sort-container' className='flex whitespace-nowrap items-center p-3'>
        <a href={`/list-sorts/${activeListSort?.id}`} className="text-sm font-semibold text-gray-900 p-2">
          <span aria-hidden="true">←</span> Go to list sort
        </a>

        <div id='DecisionMakingHeader-list-sort-container' className='w-full h-fit ml-5'>
          <SelectListSortDropdown
            listSorts={listSorts}
            onListSortSelect={(listSort) => {
              navigateToSelectedListSort(listSort?.id);
            }}
            activeListSort={activeListSort}
          />
        </div>

        <a href={'/final-decision-making'} className="text-sm font-semibold text-gray-900 p-2">
          <span aria-hidden="true">→</span> Go to Final Decision
        </a>

      </div>
      <div className='w-full h-fit'>
        <hr />
      </div>

    </div>
  );
};

export default DecisionMakingHeader;
